import {CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoRefreshToken} from 'amazon-cognito-identity-js';
import { cognitoConfig } from '../cognitoConfig'; // Your user pool configuration

const userPool = new CognitoUserPool(cognitoConfig);

export const getUserAttributes = (idToken) => {
    return new Promise((resolve, reject) => {
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser) {
            cognitoUser.getUserAttributes((err, attributes) => {
                if (err) {
                    reject(err);
                } else {
                    const userAttributes = {};
                    attributes.forEach(attribute => {
                        userAttributes[attribute.getName()] = attribute.getValue();
                    });
                    console.log("authHelper : ", userAttributes);
                    resolve(userAttributes);
                }
            });
        } else {
            reject(new Error('No current user'));
        }
    });
};

export const refreshTokens = () => {
    return new Promise((resolve, reject) => {
        const refreshToken = localStorage.getItem('refreshToken');
        const cognitoUser = userPool.getCurrentUser();

        if (cognitoUser && refreshToken) {
            cognitoUser.refreshSession(refreshToken, (err, session) => {
                if (err) {
                    reject(err);
                } else {
                    localStorage.setItem('idToken', session.getIdToken().getJwtToken());
                    localStorage.setItem('accessToken', session.getAccessToken().getJwtToken());
                    console.log("id and accessToken refreshed : ", session)
                    resolve(session);
                }
            });
        } else {
            reject(new Error('No current user or refresh token'));
        }
    });
};
