import React from 'react';
import {Grid, Typography, TextField, Box} from '@material-ui/core';
import PortfolioAllocation from './PortfolioAllocation';
import RebalanceOptions from './RebalanceOptions';
import Tooltip from "@mui/material/Tooltip";

const PostRetirementForm = ({ values, handleInputChange, setValues }) => {
    return (
        <div>
            <Box mb={3} p={2} border={1} borderRadius={8} borderColor="grey.300">
                <Typography style={{marginBottom: '0.8rem'}} variant="h5">Post-Retirement Options</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Enter your annual income after retirement. This could be from some part time job, income from side projects,
                            rental income etc.">
                            <TextField
                                label="Annual Income"
                                name="annualIncome"
                                type="number"
                                fullWidth
                                value={values.annualIncome}
                                onChange={(e) => handleInputChange('postRetirement', 'annualIncome', e.target.value)}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Enter your annual recurring expenses after retirement.
                            Do not include one time expenses like child's education, marriage expenses, car.
                            Enter only expenses that is recurring and persist during retirement">
                            <TextField
                                label="Annual Expenses"
                                name="annualExpenses"
                                type="number"
                                fullWidth
                                value={values.annualExpenses}
                                onChange={(e) => handleInputChange('postRetirement', 'annualExpenses', e.target.value)}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="This is an approximate increase in your annual income every year after retirement.
                            If you have rental income, this could be 5%. This is just an approximate amount so feel free to set this somewhere between 5-7%">
                            <TextField
                                label="Annual Income Growth (%)"
                                name="annualIncomeGrowth"
                                type="number"
                                fullWidth
                                value={values.annualIncomeGrowth}
                                onChange={(e) => handleInputChange('postRetirement', 'annualIncomeGrowth', e.target.value)}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Select the approximate allocation for your corpus post retirement.
                            We recommend using a starting allocation of 60:30:10 or 50:40:10 and using a rebalancing option.
                            Without rebalancing, we suggest using 30:60:10">
                            <PortfolioAllocation
                                values={values}
                                section="postRetirement"
                                handleInputChange={handleInputChange}
                                setValues={(newValues) => setValues({ ...values, preRetirement: newValues })} />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Rebalancing is used as a method to reduce the amount of volatility in portfolio.
                            This is especially important when you near retirement and post retirement.">
                            <RebalanceOptions
                                values={values}
                                section="postRetirement"
                                handleInputChange={handleInputChange}
                                setValues={(newValues) => setValues({ ...values, preRetirement: newValues })} />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default PostRetirementForm;
