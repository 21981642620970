import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import NavigationHeader from './NavigationHeader';
import CalculatorForm from './CalculatorForm';
import Results from './Results2';
import Footer from "./Footer";
import {Box} from "@material-ui/core";
import AuthForm from './AuthForm';
import ContactUs from "./ContactUs";
import {AuthProvider} from "./AuthContext";
import ProtectedRoute from './ProtectedRoute';
import MainLayout from "./MainLayout";

const App = () => {
    const [showResultsTab, setShowResultsTab] = useState(undefined);

    useEffect(() => {
        const handleResize = () => {
            document.documentElement.style.height = '100%';
            document.body.style.height = '100%';
            document.getElementById('root').style.height = '100%';
        };

        window.addEventListener('resize', handleResize);
        handleResize();
        if (showResultsTab === undefined) {
            const results = localStorage.getItem('resultSavedValues');
            setShowResultsTab(results !== null);
        }


        return () => window.removeEventListener('resize', handleResize);
    }, [showResultsTab]);

    return (
        <div style={{ width: '100%', minHeight: '100vh', overflowY: 'auto' }}>
            <AuthProvider>
                <Router>

                    <NavigationHeader showResultsTab={showResultsTab}/>
                    <MainLayout>
                        <Box mb={4} mt={4}>
                            <Routes>
                                <Route path="/" element={<CalculatorForm />} />
                                <Route path="/calculator" element={<CalculatorForm />} />
                                <Route path="/results2" element={<ProtectedRoute><Results setShowResultsTab={setShowResultsTab}/></ProtectedRoute>} />
                                <Route path="/contactus" element={<ProtectedRoute><ContactUs/></ProtectedRoute>} />
                                <Route path="/auth" element={<AuthForm/>} />
                            </Routes >
                        </Box>
                    </MainLayout>
                    <Footer/>
                </Router>
            </AuthProvider>
        </div>
    );
};

export default App;
