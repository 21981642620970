import React, {useContext, useState} from 'react';
import {Box, Typography, Container, Link, Snackbar, Alert} from '@mui/material';
import ReviewSection from "./ReviewSection";
import Confetti from "react-confetti";
import {AuthContext} from "./AuthContext";

const Footer = () => {
    const [showConfetti, setShowConfetti] = useState(false);
    const [openSnack, setOpenSnack] = useState(false);
    const {user} = useContext(AuthContext);

    const handleReview = async (event) => {
        // event.preventDefault();
        // alert(`Thank you for your feedback: ${event.label}!`)
        const url = 'https://gjykbdgh54.execute-api.ap-south-1.amazonaws.com/production/feedback_ratings';
        const ratings = {
            'Angry':1,
            'Sad':2,
            'Neutral':3,
            'Happy':4,
            'Very Happy':5
        };
        console.log("Footer event", ratings[event.label]);
        const uid =  user ? (user.userId + "#" + user.email) : Math.floor(Math.random() * 100) + "";
        console.log("Uid , userId", uid, user.userId)
        console.log("Rating", ratings[event.label])
        const email = user ? (user.email) : "anonymous";
        const data = {
            "user_id": uid,
            "email": email,
            "rating": ratings[event.label],
        }

        const options = {
            // mode: 'no-cors',
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "x-api-key": "90hyNSm5NM6w628siTvzAajZUDMbkyEM2ArixSBy"
            },
            body: JSON.stringify(data),
        };

        if (ratings[event.label] === 5) {
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 5000);
        }
        try {
            const response = await submit(url, options);
            if (response.ok) {
                setOpenSnack(true);
            }
        } catch (error) {
            console.error('Error making API call', error);
            alert('There was an error submitting your feedback! Could you please send your feedback to feedbacks@firecalc.in');
        }
    };

    async function submit(url, options){
        return fetch(url, options);
    }


    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnack(false);
    };

    return (
        <Box bgcolor="#f4f4f4" py={3} color="#333333">
            <Container maxWidth="lg">
                <Typography variant="body1" align="center" gutterBottom>
                    © {new Date().getFullYear()} FireCalc.in. All rights reserved.
                </Typography>
                <ReviewSection handleReview={handleReview} />
                <Typography mt={5} variant="body2" align="justify" gutterBottom>
                    Disclaimer: Content Tools and Resources on FireCalc.in is for informational and educational purposes only.
                    We are NOT financial advisors! Please do not consider anything on FireCalc.in as tax, investment, or legal advice.
                </Typography>
            </Container>
            {showConfetti && <Confetti />}
            <Snackbar open={openSnack} autoHideDuration={30000} onClose={handleSnackClose}>
                <Alert
                    onClose={handleSnackClose}
                    severity="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Thank you so much for your rating! Please sign up to get updates on bug fixes and new releases. Also, please leave a feedback to help us improve this tool.
                </Alert>
            </Snackbar>
        </Box>
    )
};

export default Footer;


{/*<Box display="flex" justifyContent="space-between" mb={2} mt={4}>*/}
{/*    <Box>*/}
{/*        <Typography variant="h6">Product</Typography>*/}
{/*        <Typography variant="body2">*/}
{/*            <Link href="/pricing" color="inherit" underline="hover">Pricing</Link>*/}
{/*            <br />*/}
{/*            <Link href="/monte-carlo" color="inherit" underline="hover">Monte Carlo Simulation</Link>*/}
{/*            <br />*/}
{/*            <Link href="/release-history" color="inherit" underline="hover">Release History</Link>*/}
{/*        </Typography>*/}
{/*    </Box>*/}
{/*    <Box>*/}
{/*        <Typography variant="h6">Legal</Typography>*/}
{/*        <Typography variant="body2">*/}
{/*            <Link href="/terms" color="inherit" underline="hover">Terms of Service</Link>*/}
{/*            <br />*/}
{/*            <Link href="/privacy" color="inherit" underline="hover">Privacy Policy</Link>*/}
{/*        </Typography>*/}
{/*    </Box>*/}
{/*    <Box>*/}
{/*        <Typography variant="h6">Resources</Typography>*/}
{/*        <Typography variant="body2">*/}
{/*            <Link href="/blog" color="inherit" underline="hover">Blog</Link>*/}
{/*            <br />*/}
{/*            <Link href="/newsletter" color="inherit" underline="hover">Newsletter</Link>*/}
{/*            <br />*/}
{/*            <Link href="/session" color="inherit" underline="hover">1-on-1 Session</Link>*/}
{/*            <br />*/}
{/*            <Link href="/financial-terms" color="inherit" underline="hover">Financial Terms</Link>*/}
{/*            <br />*/}
{/*            <Link href="/affiliate-program" color="inherit" underline="hover">Affiliate Program</Link>*/}
{/*        </Typography>*/}
{/*    </Box>*/}
{/*    <Box>*/}
{/*        <Typography variant="h6">Contact</Typography>*/}
{/*        <Typography variant="body2">*/}
{/*            <Link href="/instagram" color="inherit" underline="hover">Instagram</Link>*/}
{/*            <br />*/}
{/*            <Link href="/email" color="inherit" underline="hover">Email</Link>*/}
{/*            <br />*/}
{/*            <Link href="/about" color="inherit" underline="hover">About</Link>*/}
{/*        </Typography>*/}
{/*    </Box>*/}
{/*</Box>*/}