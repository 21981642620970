import React, { useState, useEffect } from 'react';
import {Box, Typography, Slider, TextField} from '@material-ui/core';
import Grid from '@mui/material/Unstable_Grid2';
import {Button, ButtonGroup} from "@mui/material";

const PortfolioAllocations = ({ values, section, handleInputChange, setValues }) => {
    const [highlight, setHighlight] = useState(false);
    const [error, setError] = useState(false);
    const [isNotMobile, setIsNotMobile] = useState(true);
    const {equity, bonds, cash} = values;
    const total = equity + bonds + cash;

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 600px)');
        setIsNotMobile(mediaQuery.matches !== true);
        if (total === 100) {
            setHighlight(true);
            setError(false);
            setTimeout(() => setHighlight(false), 500);
        } else {
            setError(true);
        }
    }, [total]);

    const predefinedOptions = {
        career: {
            early: { equity: 80, bonds: 10, cash: 10 },
            mid: { equity: 70, bonds: 20, cash: 10 },
            late: { equity: 60, bonds: 30, cash: 10 },
        },
        risk: {
            aggressive: { equity: 80, bonds: 10, cash: 10 },
            moderate: { equity: 50, bonds: 40, cash: 10 },
            conservative: { equity: 30, bonds: 60, cash: 10 },
        },
    };

    const handlePredefinedChange = (section, newAllocation) => {
        handleInputChange(section, 'equity', newAllocation.equity);
        handleInputChange(section, 'bonds', newAllocation.bonds);
        handleInputChange(section, 'cash', newAllocation.cash);
    };

    const sliderProps = {
        min: 0,
        max: 100,
        step: 1,
        valueLabelDisplay: 'auto',
    };

    return (
        <Box
            mb={3}
            p={3}
            className={`portfolio-mix ${error ? 'bg-danger' : ''} ${highlight ? 'bg-success' : ''}`}
            style={{ border: '1px solid #ccc', borderRadius: '8px' }}
        >
            <Typography variant="h6">Portfolio Allocation</Typography>
            <Box mt={3} mb={3} p={3} style={{ border: '1px solid #ccc', borderRadius: '8px'}}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid fullWidth item xs={12} alignItems="center" justifyContent="center">
                        <ButtonGroup variant="outlined" aria-label="Basic button group" fill={true} orientation={isNotMobile ? "horizontal" : "vertical"} fullWidth>
                            <Button sx={{marginRight: '3px'}} size="small" variant="outlined" onClick={() => handlePredefinedChange(section, predefinedOptions.risk.aggressive)}>
                                Aggressive
                            </Button>
                            <Button sx={{marginRight: '3px'}} size="small" variant="outlined" onClick={() => handlePredefinedChange(section, predefinedOptions.risk.moderate)}>
                                Moderate
                            </Button>
                            <Button sx={{marginRight: '3px'}} size="small" variant="outlined" onClick={() => handlePredefinedChange(section, predefinedOptions.risk.conservative)}>
                                Conservative
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={3}>
                <Typography variant="subtitle1">Adjust Your Allocations</Typography>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <Typography variant="subtitle2">Equity</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Slider
                            value={equity}
                            onChange={(e, newValue) => handleInputChange(section, 'equity', newValue)}
                            {...sliderProps}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={equity}
                            onChange={(e) => handleInputChange(section, 'equity', Number(e.target.value))}
                            variant={isNotMobile ? "outlined" : "standard"}
                            size={isNotMobile ? "small" : "medium"}
                            inputProps={{ min: 0, max: 100, className: 'no-arrows'}}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant="subtitle2">Bonds</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Slider
                            value={bonds}
                            onChange={(e, newValue) => handleInputChange(section, 'bonds', newValue)}
                            {...sliderProps}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={bonds}
                            onChange={(e) => handleInputChange(section, 'bonds', Number(e.target.value))}
                            variant={isNotMobile ? "outlined" : "standard"}
                            size={isNotMobile ? "small" : "medium"}
                            inputProps={{ min: 0, max: 100, className: 'no-arrows' }}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Typography variant="subtitle2">Cash</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Slider
                            value={cash}
                            onChange={(e, newValue) => handleInputChange(section, 'cash', newValue)}
                            {...sliderProps}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={cash}
                            onChange={(e) => handleInputChange(section, 'cash', Number(e.target.value))}
                            variant={isNotMobile ? "outlined" : "standard"}
                            size={isNotMobile ? "small" : "medium"}
                            inputProps={{ min: 0, max: 100, className: 'no-arrows'}}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                {error && <Typography color="error">Total allocation must be 100%</Typography>}
            </Box>
        </Box>
    );
};

export default PortfolioAllocations;
