// src/components/MainLayout.js
import React from 'react';
import { Box, Container, CssBaseline, useMediaQuery, useTheme } from '@mui/material';

const MainLayout = ({ children }) => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            <CssBaseline />
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100vw', height: '100vh' }}>
                {isDesktop && (
                    <Box
                        sx={{
                            width: '20%', // Increased from 10% to 15%
                            bgcolor: '#f0f0f0',
                            height: '100vh',
                            flexShrink: 0,
                        }}
                    >
                        {/* Left Sidebar for Ads */}
                        <div className="ad-slot"></div>
                    </Box>
                )}
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: '#ffffff',
                        overflowY: 'auto',
                    }}
                >
                    <Container maxWidth="lg">{children}</Container>
                </Box>
                {isDesktop && (
                    <Box
                        sx={{
                            width: '20%', // Increased from 10% to 15%
                            bgcolor: '#f0f0f0',
                            height: '100vh',
                            flexShrink: 0,
                        }}
                    >
                        {/* Right Sidebar for Ads */}
                        <div className="ad-slot"></div>
                    </Box>
                )}
            </Box>
        </>
    );
};

export default MainLayout;
