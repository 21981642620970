import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
    SentimentVeryDissatisfied,
    SentimentDissatisfied,
    SentimentNeutral,
    SentimentSatisfied,
    SentimentVerySatisfied
} from '@mui/icons-material';

const ReviewSection = ({handleReview}) => {
    const [selected, setSelected] = useState(null);

    const handleHover = (index) => {
        setSelected(index);
    };

    const handleLeave = () => {
        setSelected(null);
    };

    const smileys = [
        { component: SentimentVeryDissatisfied, label: 'Angry' },
        { component: SentimentDissatisfied, label: 'Sad' },
        { component: SentimentNeutral, label: 'Neutral' },
        { component: SentimentSatisfied, label: 'Happy' },
        { component: SentimentVerySatisfied, label: 'Very Happy' },
    ];

    return (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
            <Typography variant="subtitle1" gutterBottom>We have spent a lot of time and effort to develop this app. Could you please provide us a feedback on contact us page?</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                {smileys.map((smiley, index) => (
                    <Box
                        key={index}
                        onMouseEnter={() => handleHover(index)}
                        onMouseLeave={handleLeave}
                        onClick={() => handleReview(smiley)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {selected !== null && selected >= index ? (
                            React.createElement(smiley.component, { color: 'primary', fontSize: 'medium' })
                        ) : (
                            React.createElement(smiley.component, { fontSize: 'medium' })
                        )}
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default ReviewSection;
