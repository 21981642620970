import React from 'react';
import {Grid, Typography, TextField, Box} from '@material-ui/core';
import PortfolioAllocation from './PortfolioAllocation';
import RebalanceOptions from './RebalanceOptions';
import Tooltip from "@mui/material/Tooltip";

const PreRetirementForm = ({ values, handleInputChange, setValues }) => {
    return (
        <div>
            <Box mb={3} p={3} border={1} borderRadius={8} borderColor="grey.300">
                <Typography style={{marginBottom: '0.8rem'}} variant="h5">Pre-Retirement Options</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Enter your annual income before retirement.">
                            <TextField
                                label="Annual Income"
                                name="annualIncome"
                                type="number"
                                fullWidth
                                value={values.annualIncome}
                                onChange={(e) => handleInputChange('preRetirement', 'annualIncome', e.target.value)}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Enter your recurring annual expenses before retirement.">
                            <TextField
                                label="Annual Expenses"
                                name="annualExpenses"
                                type="number"
                                fullWidth
                                value={values.annualExpenses}
                                onChange={(e) => handleInputChange('preRetirement', 'annualExpenses', e.target.value)}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="This is an approximate increase in your annual income every year before retirement.
                            If you are employed, this could be your average annual hike every year.">
                            <TextField
                                label="Annual Income Growth (%)"
                                name="annualIncomeGrowth"
                                type="number"
                                fullWidth
                                value={values.annualIncomeGrowth}
                                onChange={(e) => handleInputChange('preRetirement', 'annualIncomeGrowth', e.target.value)}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        {/*<PortfolioAllocation values={values} handleChange={handleChange} />*/}
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Select the approximate allocation of your existing corpus and
                            how you would be investing your savings every year before retirement.">
                            <PortfolioAllocation
                                values={values}
                                section="preRetirement"
                                handleInputChange={handleInputChange}
                                setValues={(newValues) => setValues({ ...values, preRetirement: newValues })} />
                            {/*setValues={(newValues) => setValues({ ...values, ...newValues })} />*/}
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Rebalancing is used as a method to reduce the amount of volatility in portfolio.
                            For example. with constant rebalancing, at the end of every year you check the allocation in equity, bonds and cash
                            and bring it back to desired allocation. This could mean selling one asset class and buying another.">
                            <RebalanceOptions
                                values={values}
                                section="preRetirement"
                                handleInputChange={handleInputChange}
                                setValues={(newValues) => setValues({ ...values, preRetirement: newValues })} />
                            {/*setValues={(newValues) => setValues({ ...values, ...newValues })} />*/}
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};

export default PreRetirementForm;
