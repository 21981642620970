import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chart from 'react-apexcharts'; // Assume you are using ApexCharts for the visualizations
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Button} from "@material-ui/core";

const Results = () => {
    const [successPercentage, setSuccessPercentage] = useState(null)
    const [failedSimulations, setFailedSimulations] = useState(null)
    const [values, setValues] = useState(null)
    // State variables for user controls
    const [showValuesInLakhs, setShowValuesInLakhs] = useState(false);

    useEffect(() => {
        const resultsData = JSON.parse(localStorage.getItem('resultsData'));
        if (resultsData) {
            console.log('Loaded data from local storage:', resultsData);
            const {successPercentage, failedSimulations, values} = resultsData;
            // if (successPercentage) {
                setSuccessPercentage(resultsData.successPercentage);
                console.log("sp has been set : ", successPercentage);
            // }
            // if (failedSimulations) {
                setFailedSimulations(resultsData.failedSimulations);
                console.log("fs has been set : ", failedSimulations);
            // }
            // if (values) {
                setValues(resultsData.values);
                console.log("values has been set : ", values);
            // }
        } else {
            console.log('No data found in local storage');
        }
    }, []);

    // const location = useLocation();
    const navigate = useNavigate();
    if ((successPercentage === null || failedSimulations === null || values === null)) {
        return <Typography>Loading...</Typography>;
    }

    // const {
    //     successPercentage,
    //     failedSimulations,
    //     ...values
    // } = location.state || {};

    var maxYears = 0;
    for (let i = 0; i < failedSimulations.length; i++) {
        const innerListSize = failedSimulations[i].length;

        // Update maxSize if the current inner list size is larger
        if (innerListSize > maxYears) {
            maxYears = innerListSize;
        }
    }


    // Prepare data for the chart (example for failed simulations)
    const series = failedSimulations.map((simulation, index) => ({
        name: `Simulation ${index + 1}`,
        data: simulation.map(([corpus, year]) => ({ x: year, y: corpus }))
    }));




    const options = {
        chart: {
            type: 'line',
            height: 700,
            zoom: {
                enabled: false
            }
        },
        xaxis: {
            title: {
                text: 'Year',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'Arial, sans-serif',
                    color: '#333'
                }
            },
            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'Arial, sans-serif',
                    color: '#666'
                }
            },
            tickPlacement: 'between',
            max: Math.min(values.retirementYear - values.age + values.yearsInRetirement, maxYears),
            // stepSize: xAxisInterval,
            min:0,
            hideOverlappingLabels: true
            // tickAmount: yearsInRetirement + 1,
            // tickPlacement: 'on',
        },
        yaxis: {
            title: {
                text: 'Corpus (in ' + (showValuesInLakhs ? 'Lakhs' : 'Rupees') + ')',
                style: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontFamily: 'Arial, sans-serif',
                    color: '#333'
                }
            },
            labels: {
                style: {
                    fontSize: '12px',
                    fontFamily: 'Arial, sans-serif',
                    color: '#666'
                },
                formatter: function (value) {
                    return showValuesInLakhs ?
                        (value / 100000).toFixed(2) :
                        value.toLocaleString('en-IN');
                }
            },
            min: 0,
            hideOverlappingLabels:true
        },
        tooltip: {
            shared: true,
            intersect: false
        },
        animations: {
            enabled: false,
            easing: 'easeinout',
            speed: 2000,
            animateGradually: {
                enabled: false,
                delay: 150
            },
            dynamicAnimation: {
                enabled: false,
                speed: 350
            }
        }
    };

    return (
        <Box sx={{ p: 3, backgroundColor: '#f8f9fa', minHeight: '100vh' }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 2, color: '#333' }}>
                Simulation Results
            </Typography>
            <Box sx={{ backgroundColor: '#fff', p: 3, borderRadius: '8px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
                <Typography variant="h5" sx={{ mb: 2, color: '#333' }}>
                    Failed simulations: {100 - successPercentage}%
                </Typography>
                <Typography variant="h6" sx={{ mb: 2, color: '#333' }}>
                    User Inputs
                </Typography>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ color: '#666' }}>
                        Age: {values.age}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666' }}>
                        Retirement Year: {values.retirementYear}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666' }}>
                        Years in Retirement: {values.yearsInRetirement}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#666' }}>
                        Current Corpus: ₹{values.currentCorpus.toLocaleString('en-IN')}
                    </Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Pre-Retirement
                    </Typography>
                    <Typography variant="body1">
                        Annual Income: ₹{values.preRetirement.annualIncome.toLocaleString('en-IN')}
                    </Typography>
                    <Typography variant="body1">
                        Annual Expenses: ₹{values.preRetirement.annualExpenses.toLocaleString('en-IN')}
                    </Typography>
                    <Typography variant="body1">
                        Annual Income Growth: {values.preRetirement.annualIncomeGrowth}%
                    </Typography>
                    <Typography variant="body1">
                        Equity: {values.preRetirement.equity}%
                    </Typography>
                    <Typography variant="body1">
                        Bonds: {values.preRetirement.bonds}%
                    </Typography>
                    <Typography variant="body1">
                        Cash: {values.preRetirement.cash}%
                    </Typography>
                    <Typography variant="body1">
                        Rebalance Enabled: {values.preRetirement.rebalanceEnabled ? 'Yes' : 'No'}
                    </Typography>
                    {values.preRetirement.rebalanceEnabled && (
                        <>
                            <Typography variant="body1">
                                Rebalance Type: {values.preRetirement.rebalanceType}
                            </Typography>
                            {values.preRetirement.rebalanceType === 'decreasing' && (
                                <>
                                    <Typography variant="body1">
                                        Decrease Interval: {values.preRetirement.decreaseInterval} years
                                    </Typography>
                                    <Typography variant="body1">
                                        Decrease Percentage: {values.preRetirement.decreasePercentage}%
                                    </Typography>
                                    <Typography variant="body1">
                                        Minimum Equity: {values.preRetirement.minEquity}%
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                    <Typography variant="h6" gutterBottom>
                        Post-Retirement
                    </Typography>
                    <Typography variant="body1">
                        Annual Income: ₹{values.postRetirement.annualIncome.toLocaleString('en-IN')}
                    </Typography>
                    <Typography variant="body1">
                        Annual Expenses: ₹{values.postRetirement.annualExpenses.toLocaleString('en-IN')}
                    </Typography>
                    <Typography variant="body1">
                        Annual Income Growth: {values.postRetirement.annualIncomeGrowth}%
                    </Typography>
                    <Typography variant="body1">
                        Equity: {values.postRetirement.equity}%
                    </Typography>
                    <Typography variant="body1">
                        Bonds: {values.postRetirement.bonds}%
                    </Typography>
                    <Typography variant="body1">
                        Cash: {values.postRetirement.cash}%
                    </Typography>
                    <Typography variant="body1">
                        Rebalance Enabled: {values.postRetirement.rebalanceEnabled ? 'Yes' : 'No'}
                    </Typography>
                    {values.postRetirement.rebalanceEnabled && (
                        <>
                            <Typography variant="body1">
                                Rebalance Type: {values.postRetirement.rebalanceType}
                            </Typography>
                            {values.postRetirement.rebalanceType === 'decreasing' && (
                                <>
                                    <Typography variant="body1">
                                        Decrease Interval: {values.postRetirement.decreaseInterval} years
                                    </Typography>
                                    <Typography variant="body1">
                                        Decrease Percentage: {values.postRetirement.decreasePercentage}%
                                    </Typography>
                                    <Typography variant="body1">
                                        Minimum Equity: {values.postRetirement.minEquity}%
                                    </Typography>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Box>
            {/*<Box sx={{ mt: 3 }}>*/}
            {/*    <Chart options={options} series={series} type="line" height={350} />*/}
            {/*</Box>*/}
            {successPercentage !== 100 && ( <div>
                    <Box sx={{ mt: 3 }}>
                        <FormControlLabel
                            control={<Checkbox checked={showValuesInLakhs} onChange={(e) => setShowValuesInLakhs(e.target.checked)} />}
                            label="Show values in Lakhs"
                        />
                        <Chart options={options} series={series} type="line" height={350} />
                    </Box>
                </div>
            )}
            <Box textAlign="center" mt={4}>
                <Button variant="contained" color="primary" onClick={() => navigate('/', { state: values })}>
                    Back
                </Button>
            </Box>
        </Box>
    );
};

export default Results;
