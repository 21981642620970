import React, {useContext, useEffect, useState} from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    CircularProgress,
    Grid, InputAdornment,
    TextField,
} from '@material-ui/core';
import PreRetirementForm from './PreRetirementForm';
import PostRetirementForm from './PostRetirementForm';
import {
    DEFAULT_AGE,
    DEFAULT_RETIREMENT_YEAR,
    DEFAULT_YEARS_IN_RETIREMENT,
    DEFAULT_CURRENT_CORPUS
} from '../constants';
import {AuthContext} from "./AuthContext";

const CalculatorForm = (props) => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const savedValues = localStorage.getItem('calculatorFormValues');
    const initialFormState = props.location?.state || props?.state || savedValues
        ? JSON.parse(savedValues)
        : {
        age: DEFAULT_AGE,
        retirementYear: DEFAULT_RETIREMENT_YEAR,
        yearsInRetirement: DEFAULT_YEARS_IN_RETIREMENT,
        currentCorpus: DEFAULT_CURRENT_CORPUS,
        preRetirement: {
            annualIncome: 0,
            annualExpenses: 0,
            annualIncomeGrowth: 0,
            equity: 50,
            bonds: 30,
            cash: 20,
            rebalanceEnabled: false,
            rebalanceType: 'constant',
            decreaseInterval: 1,
            decreasePercentage: 5,
            minEquity: 20
        },
        postRetirement: {
            annualIncome: 1000,
            annualExpenses: 2000,
            annualIncomeGrowth: 0,
            equity: 50,
            bonds: 30,
            cash: 20,
            rebalanceEnabled: false,
            rebalanceType: 'constant',
            decreaseInterval: 1,
            decreasePercentage: 5,
            minEquity: 20
        }
    };
    const [loading, setLoading] = useState(false);


    // Simulations
    const [simulations, setSimulations] = useState(10);
    const [simulationsError, setSimulationsError] = useState('');
    const [values, setValues] = useState(initialFormState);

    // Save form values to local storage whenever they change
    useEffect(() => {
        if (values !== null) {
            localStorage.setItem('calculatorFormValues', JSON.stringify(values));
        }
    }, [values]);

    const handleSimulationsChange = (e) => {
        const value = Number(e.target.value);
        setSimulations(value);
        if (value > 100) {
            setSimulationsError(
                'The max limit for free users is 10 simulations. If you need more simulations, try the premium version of the app by' +
                ' contacting me :D'
            );
        } else {
            setSimulationsError('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (simulations > 100) {
            setSimulationsError(
                'The max limit for free users is 100 simulations. If you need more simulations, try the premium version of the app by' +
                ' contacting freefincalc@gmail.com'
            );
            return;
        }
        setSimulationsError('');
        setLoading(true);

        const { preRetirement, postRetirement } = values;
        console.log("Calc values : ", values)
        const data = {
                operation : "calculate",
                payload : {
                    "initial_corpus" : values.currentCorpus,
                    "annual_expenses_post_retirement" : postRetirement.annualExpenses,
                    "annual_expenses_pre_retirement" : preRetirement.annualExpenses,
                    "annual_income_growth_pre_retirement" : preRetirement.annualIncomeGrowth/100,
                    "annual_income_pre_retirement" : preRetirement.annualIncome,
                    "annual_income_post_retirement" : postRetirement.annualIncome,
                    "annual_income_growth_post_retirement" : postRetirement.annualIncomeGrowth/100,
                    "withdrawal_inflation" : 0.07,
                    "current_age" : values.age,
                    "retirement_age" : values.retirementYear,
                    "years_in_retirement" : values.yearsInRetirement,
                    "simulations" : simulations,
                    "current_equity" : preRetirement.equity/100,
                    "current_bonds" : preRetirement.bonds/100,
                    "current_cash" : preRetirement.cash/100,
                    "post_retirement_equity" : postRetirement.equity/100,
                    "post_retirement_bonds" : postRetirement.bonds/100,
                    "post_retirement_cash" : postRetirement.cash/100,
                    "rebalance_pre_retirement" : preRetirement.rebalanceEnabled,
                    "rebalance_pre_constant_portfolio_mix" : preRetirement.rebalanceType === 'constant',
                    "rebalance_pre_decrease_equity_periodically" : preRetirement.rebalanceType === 'decreasing',
                    "rebalance_pre_decreasing_equity_interval_in_years" : preRetirement.decreaseInterval,
                    "rebalance_pre_decreasing_equity_percentage" : preRetirement.decreasePercentage/100,
                    "rebalance_pre_minimum_equity_exposure" : preRetirement.minEquity/100,
                    "rebalance_post_retirement" : postRetirement.rebalanceEnabled,
                    "rebalance_post_constant_portfolio_mix" : postRetirement.rebalanceType === 'constant',
                    "rebalance_post_decrease_equity_periodically" : postRetirement.rebalanceType === 'decreasing',
                    "rebalance_post_decreasing_equity_interval_in_years" : postRetirement.decreaseInterval,
                    "rebalance_post_decreasing_equity_percentage" : postRetirement.decreasePercentage/100,
                    "rebalance_post_minimum_equity_exposure" : postRetirement.minEquity/100
                }
        }


        const url = "https://ac9cs4v7z7.execute-api.ap-south-1.amazonaws.com/staging/firecalc_test"
        // const url = "https://pejp75ulq4.execute-api.ap-south-1.amazonaws.com/staging/firecalc_test"
        const options = {
            // mode: 'no-cors',
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(data),
        };

        try {
            const response = await calculate(url, options);
            const data = await response.json();
            const simResults = JSON.parse(data)
            const {successPercentage, failedSimulations, eoyCorpusValues} = simResults;
            localStorage.setItem('resultSavedValues', JSON.stringify({
                successPercentage,
                failedSimulations,
                eoyCorpusValues,
                values,
            }));
            if (!user) {
                navigate('/auth', { state: { callbackUrl: '/results2' } });
            } else {
                // Process form and redirect to results
                navigate('/results2');
            }
        } catch (error) {
            console.error('Error making API call', error);
            alert('There was an error processing your request. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    async function calculate(url, options){
        return fetch(url, options);
    }

    const convertValue = (originalValue, newValue) => {
        if (typeof originalValue === 'number') {
            newValue = Number(newValue);
        } else if (typeof originalValue === 'boolean') {
            newValue = newValue === 'true';
        }
        console.log(typeof originalValue, typeof newValue, originalValue, newValue);
        return newValue; // Default case: treat as string
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues((prevValues) => {
            const originalValue = prevValues[name];
            return {
                ...prevValues,
                [name]: convertValue(originalValue, value),
            };
        });
    };


    const handleNestedInputChange = (section, name, value) => {
        setValues((prevValues) => {
            const originalValue = prevValues[section][name];
            const type = typeof originalValue;
            return {
                ...prevValues,
                [section]: {
                    ...prevValues[section],
                    [name]: type === 'number' ? convertValue(originalValue, value) : value,
                },
            };
        });
    };
    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setValues((prevValues) => {
    //         const originalValue = prevValues[name];
    //         return {
    //             ...prevValues,
    //             [name]: value,
    //         };
    //     });
    // };
    //
    //
    // const handleNestedInputChange = (section, name, value) => {
    //     setValues((prevValues) => {
    //         const originalValue = prevValues[section][name];
    //         const type = typeof originalValue;
    //         return {
    //             ...prevValues,
    //             [section]: {
    //                 ...prevValues[section],
    //                 [name]: value,
    //             },
    //         };
    //     });
    // };

    return (
        <div className="container mt-4">
            <Box mb={3} p={2} border={1} borderRadius={8} borderColor="grey.300">
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Enter your current age">
                            <TextField
                                label="Age"
                                name="age"
                                type="number"
                                fullWidth
                                value={values.age}
                                onChange={handleInputChange}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Enter the age at which you wish to retire">
                            <TextField
                                fullWidth
                                label="Retirement Age"
                                name="retirementYear"
                                type="number"
                                value={values.retirementYear}
                                onChange={handleInputChange}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="After you retire, enter the number of years you will spend in retirement">
                            <TextField
                                fullWidth
                                label="Years in Retirement"
                                name="yearsInRetirement"
                                type="number"
                                value={values.yearsInRetirement}
                                onChange={handleInputChange}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Enter the amount of money that you have that is invested currently.
                            Add the value of all the investments that you have.
                            Note: Exclude the value of your house if you currently stay in it.">
                            <TextField
                                fullWidth
                                label="Current Corpus"
                                name="currentCorpus"
                                type="number"
                                value={values.currentCorpus}
                                onChange={handleInputChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                                }}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
            <Box mb={3}>
                <PreRetirementForm
                    values={values.preRetirement}
                    handleInputChange={handleNestedInputChange}
                    setValues={(newValues) => setValues({ ...values, preRetirement: newValues })} />
                <PostRetirementForm
                    values={values.postRetirement}
                    handleInputChange={handleNestedInputChange}
                    setValues={(newValues) => setValues({ ...values, postRetirement: newValues })} />
            </Box>
            <Box mb={3} p={2} border={1} borderRadius={8} borderColor="grey.300">
                    <Grid item xs={12}>
                        <Tooltip
                            arrow="true"
                            placement="top-end"
                            title="Enter the number of simulations that you wish to run on the data entered.
                            Once submitted, the tool will simulate equity and bond returns for X years.
                            where X is the years in retirement + (age at retirement - current age).
                            Returns on cash component is assumed at a constant 5%">
                            <TextField
                                fullWidth
                                label="Number of Simulations"
                                type="number"
                                value={simulations}
                                onChange={handleSimulationsChange}
                                error={!!simulationsError}
                                helperText={simulationsError}
                            />
                        </Tooltip>
                    </Grid>
            </Box>
            <Button
                onClick={handleSubmit}
                disabled={loading}
                variant="contained"
                color="primary"
            >
                {loading ? <CircularProgress size={24} /> : 'Submit'}
            </Button>
        </div>
    );
};

export default CalculatorForm;
