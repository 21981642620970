import React, {useEffect, useState} from 'react';
import {Container, Box, Typography, Button, TextField, FormControl, MenuItem, Select, InputLabel, Paper} from '@mui/material';
import {Bar, Line} from 'react-chartjs-2';
import { Chart, LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend } from 'chart.js';
import 'chart.js/auto';
import {useLocation, useNavigate} from "react-router-dom";
import Confetti from "react-confetti";

Chart.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);
const Results2 = ({ setShowResultsTab }) => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const savedValues = JSON.parse(localStorage.getItem('resultSavedValues'));
    const {successPercentage, failedSimulations, eoyCorpusValues, values} = state || savedValues;
    const [eoyCorpusSuccessfulValues, setEoyCorpusSuccessfulValues] = useState(null);
    const [showPresentValue, setShowPresentValue] = useState(false);
    const inflationRate = 0.07;
    const [yAxisUnit, setYAxisUnit] = useState('');
    const [xAxisInterval, setXAxisInterval] = useState(1);
    const [showConfetti, setShowConfetti] = useState(false);

    // Save form values to local storage whenever they change
    useEffect(() => {
        if (state !== null) {
            localStorage.setItem('resultSavedValues', JSON.stringify(state));
            setShowResultsTab(true);
        }
        if (successPercentage === 100) {
            setShowConfetti(true);
            setTimeout(() => setShowConfetti(false), 5000);
        }
    }, [setShowResultsTab, state, successPercentage]);

    console.log("EOY in Results2", eoyCorpusSuccessfulValues);

    const handleYAxisUnitChange = (event) => {
        setYAxisUnit(event.target.value);
    };

    const handleXAxisIntervalChange = (event) => {
        setXAxisInterval(parseInt(event.target.value, 10));
    };

    const formatYAxis = (value) => {
        switch (yAxisUnit) {
            case 'thousands':
                return value/1000;
            case 'lakhs':
                return value / 100000;
            case 'crores':
                return value / 10000000;
            default:
                return value;
        }
    };

    if (eoyCorpusValues !== null && eoyCorpusValues !== undefined && eoyCorpusSuccessfulValues === null) {
        setEoyCorpusSuccessfulValues(eoyCorpusValues.filter(corpus => corpus > 0));
    }

    if (successPercentage === null || successPercentage === undefined || failedSimulations === null || failedSimulations === undefined || values === undefined || values === null || eoyCorpusValues === null || eoyCorpusValues === undefined
        || eoyCorpusSuccessfulValues === null) {
        return <Typography>Loading...</Typography>;
    }

    const futureValues = eoyCorpusSuccessfulValues;
    const presentValues = futureValues.map((value, index) => {
        const years = values.retirementYear + values.yearsInRetirement - values.age;
        console.log("Values : ",values);
        const presentValue = (value / Math.pow(1 + inflationRate, years)).toFixed(2);
        console.log("Present value for {} is {} after {} years", value, presentValue, years)
        return presentValue;
    });

    if (presentValues !== null && presentValues.length > 0) {
        const maxCorpusValue = showPresentValue ? Math.max(...presentValues) : Math.max(...eoyCorpusSuccessfulValues);
        if (yAxisUnit === '') {
            if (maxCorpusValue > 10000000) {
                setYAxisUnit('crores');
            } else if (maxCorpusValue > 100000) {
                setYAxisUnit('lakhs');
            } else {
                setYAxisUnit('thousands');
            }
        }
    } else if (successPercentage === 0) {
        if (yAxisUnit === '') {
            if (values.currentCorpus > 10000000) {
                setYAxisUnit('crores');
            } else if (values.currentCorpus > 100000) {
                setYAxisUnit('lakhs');
            } else {
                setYAxisUnit('thousands');
            }
        }
    }


    function failedSimulationsChart() {
        var maxYears = 0;
        for (let i = 0; i < failedSimulations.length; i++) {
            const innerListSize = failedSimulations[i].length;

            // Update maxSize if the current inner list size is larger
            if (innerListSize > maxYears) {
                maxYears = innerListSize;
            }
        }

        // const maxLength = Math.max(failedSimulations.map(sim => sim.length));
        const maxLength = Math.min(values.retirementYear - values.age + values.yearsInRetirement + 1, maxYears + 1);
        console.log("maxLength: ", maxLength)
        const labels = Array.from({length: maxLength + 1}, (_, i) => i);
        console.log("labels: ", labels)
        const chartData = {
            labels: labels,
            datasets: failedSimulations.map((sim, index) => ({
                label: `Simulation ${index + 1}`,
                data: sim.map(([corpus, year]) => ({x: year + 1, y: corpus})),
                // data: sim.map(([year, corpus]) => ({ x: year, y: formatYAxis(corpus) })),
                borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
                fill: false,
            })),
        };

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            const value = context.raw;
                            return `${context.dataset.label}: ${formatYAxis(value.y)} ${yAxisUnit}`;
                        },
                    },
                },
            },
            scales: {
                x: {
                    min: 1,
                    ticks: {
                        callback: function (value) {
                            if (value % xAxisInterval === 0) {
                                return value;
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: 'Number of Years',
                    },
                },
                y: {
                    min: 0,
                    ticks: {
                        callback: function (value) {
                            return `${formatYAxis(value)} ${yAxisUnit}`;
                        }
                    },
                    title: {
                        display: true,
                        text: 'Corpus Value',
                    },
                }
            }
        };
        return {failedSimulationsChartData: chartData, failedSimulationsOptions: options};
    }

    const {failedSimulationsChartData, failedSimulationsOptions} = failedSimulationsChart();

    function eoyCorpusChart() {
        // Y axis corpus amount
        // x axis simulation
        // list(sim, corpus)

        const simulationsCount = eoyCorpusSuccessfulValues.length;


        // const maxLength = Math.max(failedSimulations.map(sim => sim.length));
        const labels = Array.from({ length: simulationsCount }, (v, i) => (i + 1).toString());
        console.log("Inner Labels : ", labels)
        const chartData = {
            labels: labels,
            datasets: [{
                label: showPresentValue ? 'Present Value Corpus Values' : 'Future Value Corpus Values',
                data: showPresentValue ? presentValues.map((corpus, index) => ({x: index + 1, y: corpus})) : futureValues.map((corpus, index) => ({x: index + 1, y: corpus})),
                borderWidth: 1,
                // data: sim.map(([year, corpus]) => ({ x: year, y: formatYAxis(corpus) })),
                borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
                // fill: false,
            }],
        };
        console.log(chartData);

        const options = {
            responsive: true,
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            const value = context.raw;
                            return `${context.dataset.label}: ${value}`;
                        },
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        callback: function (value) {
                            if (value % xAxisInterval === 0) {
                                return value +1;
                            }
                        }
                    },
                    title: {
                        display: true,
                        text: 'Simulations',
                    },
                },
                y: {
                    min: 0,
                    ticks: {
                        callback: function (value) {
                            return `${formatYAxis(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${yAxisUnit}`;
                        }
                    },
                    title: {
                        display: true,
                        text: 'Corpus value at end of Retirement',
                    },
                }
            }
        };
        return {eoyCorpusChartData: chartData, eoyCorpusOptions: options};
    }

    const {eoyCorpusChartData, eoyCorpusOptions} = eoyCorpusChart();

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Simulation Results
            </Typography>
            <Box mt={3} mb={2} p={2} border={1}
                 borderRadius={8}
                 borderColor="grey.300"
                 className={`${successPercentage < 70 ? 'bg-danger' : 'bg-success'} `}>
                 {/*style={{ backgroundColor: successPercentage < 90 ? 'red' : 'green' }}>*/}
                {successPercentage > 50 && ( <div>
                    <Typography variant="h5" sx={{ mt: 2, mb: 2}}>
                        Success Percentage: {successPercentage}%
                    </Typography>
                </div>)}
                {successPercentage <= 50 && ( <div>
                    <Typography variant="h5" sx={{ mt: 2, mb: 2}}>
                        Failure Percentage: {100 - successPercentage}%
                    </Typography>
                </div>)}
            </Box>
            <Box mt={2}>
                <FormControl fullWidth>
                    <InputLabel variant="standard" id="y-axis-unit-label" sx={{ ml:1, mt: .5, mb: 2, color: '#333' }}>Y-Axis Unit</InputLabel>
                    <Select
                        labelId="y-axis-unit-label"
                        value={yAxisUnit}
                        onChange={handleYAxisUnitChange}
                    >
                        <MenuItem value="thousands">Thousands</MenuItem>
                        <MenuItem value="lakhs">Lakhs</MenuItem>
                        <MenuItem value="crores">Crores</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box mt={2}>
                <FormControl fullWidth>
                    <InputLabel id="x-axis-interval-label" variant="standard"  sx={{ ml:1, mt: .5, mb: 2, color: '#333'}}>X-Axis Interval</InputLabel>
                    <Select
                        labelId="x-axis-interval-label"
                        value={xAxisInterval}
                        onChange={handleXAxisIntervalChange}
                    >
                        <MenuItem value={1}>1 Year</MenuItem>
                        <MenuItem value={2}>2 Years</MenuItem>
                        <MenuItem value={3}>3 Years</MenuItem>
                        <MenuItem value={4}>4 Years</MenuItem>
                        <MenuItem value={5}>5 Years</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Box mt={2}>
                {successPercentage !== 100 && ( <div>
                        <Typography variant="h5" gutterBottom>
                            Corpus Value over years for Failed simulations
                        </Typography>
                        <Box mt={4}>
                            <Line data={failedSimulationsChartData} options={failedSimulationsOptions} />
                        </Box>
                        <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
                            <Typography variant="h6" gutterBottom>
                                Understanding the Chart
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                This chart shows failed simulations where the value of corpus eroded completely before the end of retirement.
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                What are these simulations?
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Most basic retirement calculators today assume the equity(stocks/Mutual Funds) returns and debt(bonds) in future to be a fixed number.
                                This assumption fails to recognize the inherent volatility in equity markets and does not accurately represent real world scenarios. <br/>
                                We have employed the help of simulations where we generate the equity and bond returns based on the actual nifty 50 returns distribution in the past.<br/>
                                When we run your portfolio numbers through these generated data for all the years once we call it one simulations. So when we generate a statistically significant number of simulations, we can confidently say that there is a x% probability that your porfolio will not erode before the end of your/your spouse's life.<br/>
                                <br/>
                                Assumptions: We have assumed a uniform distribution for bond returns between 4.5% and 10.5% and a fixed rate of 5% for cash return. Inflation rate is set at a constant 7% as well<br/>
                                Note : We plan to integrate bond, cash and inflation data into our simulated data as well to get rid of above assumptions in future.<br/>
                            </Typography>
                        </Paper>
                    </div>
                )}
                {eoyCorpusSuccessfulValues.length !== 0 && (<div>
                    <Typography variant="h5" gutterBottom>
                        Corpus left over at end of retirement
                    </Typography>
                    <Box mt={4}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowPresentValue(!showPresentValue)}
                            style={{ marginBottom: '20px' }}
                        >
                            {showPresentValue ? 'Show Future Value' : 'Show Present Value'}
                        </Button>
                        <Bar data={eoyCorpusChartData} options={eoyCorpusOptions} />
                        <Box mt={3}>
                            <Paper elevation={3} style={{ padding: '20px', margin: '20px' }}>
                                <Typography variant="h6" gutterBottom>
                                    Understanding the Chart
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    This chart shows the retirement savings at the end of your retirement. The x-axis represents different simulations, and the y-axis shows the value of the savings, either in today's money (present value) or in future money (future value), depending on the chosen view.
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    What are Present and Future Values?
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    To understand this, imagine you had 1000 rupees in 1950. 5000 rupees in 1950 could buy you a small house. In order to buy the same house today, you need atleast 1.5 lakh rupees. This means your money buys less over time; its purchasing power decreases.
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Future Value:</b> This shows how much your savings will be worth in the future. If you are looking at more than 10 years in future, this value may sound absurd today.
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <b>Present Value:</b> This shows what the future savings are worth in today's terms, accounting for inflation at a constant rate of 7%.
                                </Typography>
                                <Typography variant="body1">
                                    It's like telling someone in 1950 that 1000 rupees in the 2024 is only worth 1 rupee in today's money. That is person having 1 rupee today and person in 2024 having 1000 rupees will be able to buy same/similar amount of goods and services with that money.
                                </Typography>
                            </Paper>
                        </Box>
                    </Box>
                </div>)}
            </Box>
            <Box textAlign="center" mt={4}>
                <Button variant="contained" color="primary" onClick={() => navigate('/', { state: values })}>
                    Back
                </Button>
            </Box>
            {showConfetti && <Confetti />}
        </Container>
    );
};

export default Results2;
