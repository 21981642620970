import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, Container, TextField, Typography, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import Confetti from 'react-confetti';
import Grid from "@mui/material/Unstable_Grid2";
import {AuthContext} from "./AuthContext";
import {useNavigate} from "react-router-dom";
import {getUserAttributes} from "./authHelpers";

const ContactUs = () => {
    const {navigate} = useNavigate();
    const [feedbackCategory, setFeedbackCategory] = useState('General Feedback');
    const [email, setEmail] = useState('test@gmail.com');
    const [feedback, setFeedback] = useState('test test');
    const [showConfetti, setShowConfetti] = useState(false);
    const { user } = useContext(AuthContext);

    useEffect(() => {
        console.log("IdToken :", user.idToken);
        console.log("email : ", user.email);
        console.log("userId : ", user.userId);
        console.log("userName : ", user.username);
    }, [user]);

    const handleCategoryChange = (event) => {
        setFeedbackCategory(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const url = 'https://gjykbdgh54.execute-api.ap-south-1.amazonaws.com/production/feedback_ratings';
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000);
        const uid =  user ? (user.userId + "#" + user.email) : Math.floor(Math.random() * 100) + "";
        const data = {
            "user_id": uid,
            "email": email,
            "feedbackCategory": feedbackCategory,
            "feedback": feedback,
        }

        const options = {
            // mode: 'no-cors',
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "x-api-key": "90hyNSm5NM6w628siTvzAajZUDMbkyEM2ArixSBy"
            },
            body: JSON.stringify(data),
        };

        try {
            await submit(url, options);
        } catch (error) {
            console.error('Error making API call', error);
            alert('There was an error submitting your feedback! Could you please send your feedback to feedbacks@firecalc.in');
        }
    };

    async function submit(url, options){
        return fetch(url, options);
    }

    return (
        <Container>
            <Box sx={{ py: 5, textAlign: 'center' }}>
                <Typography variant="h4" gutterBottom>Contact Us</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Email"
                                variant="outlined"
                                margin="normal"
                                value={email}
                                type ="email"
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth variant="outlined" margin="normal">
                                <InputLabel>Feedback Category</InputLabel>
                                <Select
                                    value={feedbackCategory}
                                    onChange={handleCategoryChange}
                                    label="Feedback Category"
                                    required
                                >
                                    <MenuItem value="General Feedback">General Feedback</MenuItem>
                                    <MenuItem value="Feature Request">Feature Request</MenuItem>
                                    <MenuItem value="Partnership Request">Partnership Request</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Feedback"
                                variant="outlined"
                                margin="dense"
                                multiline
                                rows={4}
                                value={feedback}
                                onChange={(e) => setFeedback(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary">Submit</Button>
                        </Grid>
                    </Grid>
                </form>
            </Box>
            {showConfetti && <Confetti />}
            {showConfetti && (
                <Typography variant="h6" color="textPrimary" align="center" sx={{ mt: 3 }}>
                    Thank you! Your feedback is very important for us to improve this website.
                </Typography>
            )}
        </Container>
    );
};

export default ContactUs;
