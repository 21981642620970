import React from 'react';
import { Typography, Grid, FormControlLabel, Checkbox, Radio, RadioGroup, FormGroup, FormControl, Box, TextField } from '@material-ui/core';

const RebalanceOptions = ({ values, section, handleInputChange, setValues }) => {
    // const handleInputChange = (event) => {
    //     const { name, value } = event.target;
    //     setValues((prevValues) => ({
    //         ...prevValues,
    //         [name]: value,
    //     }));
    // };

    // const handleCheckboxChange = (event) => {
    //     const { name, checked } = event.target;
    //     setValues((prevValues) => ({
    //         ...prevValues,
    //         [name]: checked,
    //     }));
    // };

    const { rebalanceEnabled, rebalanceType, decreaseInterval, decreasePercentage, minEquity } = values;

    return (
        <Box mt={1} p={2} border={1} borderRadius={8} borderColor="grey.300">
            <Typography variant="subtitle1">Rebalancing Options</Typography>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={rebalanceEnabled}
                            onChange={(e, newValue) => handleInputChange(section, 'rebalanceEnabled', newValue)}
                            color="primary"
                            name="rebalanceEnabled"
                        />
                    }
                    label="Enable Rebalancing"
                />
                {rebalanceEnabled && (
                    <div>
                        <FormControl component="fieldset">
                            <RadioGroup
                                aria-label="rebalance-type"
                                name="rebalanceType"
                                value={rebalanceType}
                                onChange={(e) => handleInputChange(section, 'rebalanceType', e.target.value)}
                            >
                                <FormControlLabel value="constant" control={<Radio />} label="Constant Equity Rebalancing" />
                                <FormControlLabel value="decreasing" control={<Radio />} label="Decreasing Equity Rebalancing" />
                            </RadioGroup>
                        </FormControl>
                        {rebalanceType === 'decreasing' && (
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <Typography>Decrease Interval (years)</Typography>
                                    <TextField
                                        type="number"
                                        value={decreaseInterval}
                                        name="decreaseInterval"
                                        onChange={(e) => handleInputChange(section, 'decreaseInterval', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography>Percentage Equity to Decrease</Typography>
                                    <TextField
                                        type="number"
                                        value={decreasePercentage}
                                        name="decreasePercentage"
                                        onChange={(e) => handleInputChange(section, 'decreasePercentage', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography>Minimum Equity Allocation (%)</Typography>
                                    <TextField
                                        type="number"
                                        value={minEquity}
                                        name="minEquity"
                                        onChange={(e) => handleInputChange(section, 'minEquity', e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </div>
                )}
            </FormGroup>
        </Box>
    );
};

export default RebalanceOptions;
