// src/components/AuthForm.js

import React, {useContext, useEffect, useState} from 'react';
import {
    Container,
    Box,
    TextField,
    Button,
    Typography,
} from '@mui/material';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { cognitoConfig } from '../cognitoConfig';
import {useLocation, useNavigate} from 'react-router-dom';
import ConfirmSignUp from './ConfirmSignUp';
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import {AuthContext} from "./AuthContext";

const userPool = new CognitoUserPool(cognitoConfig);

const AuthForm = () => {
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [formType, setFormType] = useState('signin');
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        phoneNumber: '+919234512345',
        name: '',
        locale: 'en',
        city: '',
    });
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [confirmationRequired, setConfirmationRequired] = useState(false);
    const callbackUrl = location.state?.callbackUrl || '/';

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSignup = () => {
        const { username, password, email, phoneNumber, name, locale, city } = formData;

        userPool.signUp(
            username,
            password,
            [
                { Name: 'email', Value: email },
                { Name: 'phone_number', Value: phoneNumber },
                { Name: 'nickname', Value: name },
                { Name: 'locale', Value: locale },
                { Name: 'custom:city', Value: city },
            ],
            [],
            (err, result) => {
                if (err) {
                    setError(err.message || JSON.stringify(err));
                    return;
                }
                if (result) {
                    console.log("After signup : before confirmation : ",result);
                }
                // Automatic sign-in after successful signup
                setConfirmationRequired(true);
            }
        );
    };

    const handleSignin = (username, password) => {
        const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails({
            Username: username,
            Password: password,
        });

        const userData = {
            Username: username,
            Pool: userPool,
        };

        const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (result) => {
                console.log("result inside signin page : ", result);
                const idToken = result.getIdToken().getJwtToken();
                const accessToken = result.getAccessToken().getJwtToken();
                const refreshToken = result.getRefreshToken().getToken();
                const email = result.getIdToken().payload?.email;
                const userId = result.getIdToken().payload.sub;

                const userData = { username, email, userId, idToken, accessToken, refreshToken };
                setUser(userData);
                localStorage.setItem('user', JSON.stringify(userData));
                navigate(callbackUrl || '/');
            },
            onFailure: (err) => {
                setError(err.message || JSON.stringify(err));
            },
        });
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        setError('');
        setMessage('');
        if (formType === 'signup') {
            handleSignup();
        } else {
            handleSignin(formData.username, formData.password);
        }
    };

    if (confirmationRequired) {
        return <ConfirmSignUp username={formData.username} password={formData.password} setUser={setUser} callbackUrl={callbackUrl} handleSignIn={handleSignin}/>;
    }

    return (
        <Container maxWidth="sm">
            <Box mt={5} p={3} boxShadow={3} bgcolor="background.paper">
                <Typography variant="h4" component="h1" gutterBottom>
                    {formType === 'signup' ? 'Sign Up' : 'Sign In'}
                </Typography>
                <form onSubmit={handleFormSubmit}>
                    {formType === 'signup' && (
                        <>
                            <TextField
                                label="Name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                required
                            />
                            <TextField
                                label="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                required
                                type="email"
                            />
                            <TextField
                                label="Phone Number"
                                name="phoneNumber"
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                required
                                type="tel"
                                disabled={true}
                            />
                            <TextField
                                label="Locale"
                                name="locale"
                                value={formData.locale}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                                required
                            />
                            <TextField
                                label="City"
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                                fullWidth
                                margin="normal"
                            />
                        </>
                    )}
                    <TextField
                        label="Username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                        type="password"
                    />
                    {error && (
                        <Typography variant="body2" color="error" align="center">
                            {error}
                        </Typography>
                    )}
                    <Box mt={2}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            {formType === 'signup' ? 'Sign Up' : 'Sign In'}
                        </Button>
                    </Box>
                </form>
                <Box mt={2}>
                    <Typography variant="body2" align="center">
                        {formType === 'signup' ? (
                            <>
                                Already have an account?{' '}
                                <Button color="secondary" onClick={() => setFormType('signin')}>
                                    Sign In
                                </Button>
                            </>
                        ) : (
                            <>
                                Don't have an account?{' '}
                                <Button color="secondary" onClick={() => setFormType('signup')}>
                                    Sign Up
                                </Button>
                            </>
                        )}
                    </Typography>
                </Box>
                {error && (
                    <Box mt={2}>
                        <Typography color="error" align="center">
                            {error}
                        </Typography>
                    </Box>
                )}
                {message && (
                    <Box mt={2}>
                        <Typography color="primary" align="center">
                            {message}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Container>
    );
};

export default AuthForm;
