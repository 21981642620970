import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Container } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../assets/logo.png';
import {Button} from "@material-ui/core";
import {CognitoUserPool} from "amazon-cognito-identity-js";
import {cognitoConfig} from "../cognitoConfig";
import results from "./Results";
import {AuthContext} from "./AuthContext";

const NavigationHeader = ({showResultsTab}) => {
    const [open, setOpen] = useState(false);
    const { user, setUser} = useContext(AuthContext);

    const toggleDrawer = (isOpen) => () => {
        setOpen(isOpen);
    };

    const handleSignOut = () => {
        localStorage.clear();
        setUser(null);
        window.location.href = '/auth';
    };

    return (
        <AppBar position="relative" color="transparent" elevation={0} style={{marginBottom: '15px', color: '#FFFFFF' , backgroundColor: '#1D366F'}}>
            <Container maxWidth="lg">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1}}>
                        <img src={logo} alt="Logo" style={{ height: '200px', marginRight: '5px' }} />
                    </Typography>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Drawer
                        anchor="right"
                        open={open}
                        onClose={toggleDrawer(false)}
                    >
                        <List>
                            <ListItem button component={Link} to="/calculator" onClick={toggleDrawer(false)} sx={{ color: '#FFFFFF', backgroundColor: '#1D366F'}}>
                                <ListItemText primary="Calculator" />
                            </ListItem>
                            {showResultsTab &&
                                (<ListItem button component={Link} to="/results2" onClick={toggleDrawer(false)} sx={{ color: '#FFFFFF', backgroundColor: '#1D366F'}}>
                                    <ListItemText primary="Results" />
                                </ListItem>)}
                            <ListItem button component={Link} to="/contactus" onClick={toggleDrawer(false)} sx={{ color: '#FFFFFF', backgroundColor: '#1D366F'}}>
                                <ListItemText primary="Contact" />
                            </ListItem>
                            {user ? (
                                <ListItem button onClick={toggleDrawer(false) && handleSignOut} sx={{ color: '#FFFFFF', backgroundColor: '#1D366F'}}>
                                    <ListItemText primary="Sign Out" />
                                </ListItem>
                            ) : (
                                <ListItem button component={Link} to="/auth" onClick={toggleDrawer(false)} sx={{ color: '#FFFFFF', backgroundColor: '#1D366F'}}>
                                    <ListItemText primary="Sign In" />
                                </ListItem>
                            )}

                        </List>
                    </Drawer>
                    <Typography variant="h6" component="div" sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Button color="inherit" component={Link} to="/calculator" sx={{ color: '#1D366F', backgroundColor: '#08C076', marginRight: '5px'}}>Calculator</Button>
                        {user && showResultsTab &&
                            (<Button color="inherit" component={Link} to="/results2" sx={{ color: '#1D366F', backgroundColor: '#08C076', marginRight: '5px'}}>Results</Button>)
                        }
                        {user &&
                            <Button color="inherit" component={Link} to="/contactus" sx={{ color: '#1D366F', backgroundColor: '#08C076', marginRight: '5px'}}>Contact</Button>
                        }
                        {user ? (
                            <Button
                                color="inherit"
                                sx={{ color: '#1D366F', backgroundColor: '#08C076', marginRight: '5px'}}
                                onClick={handleSignOut}>
                                Sign Out
                            </Button>
                        ) : (
                            <>
                                <Button color="inherit" component={Link} to="/auth" sx={{ color: '#1D366F', backgroundColor: '#08C076', marginRight: '5px'}}>Sign In</Button>
                            </>
                        )}
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavigationHeader;
