// src/components/ConfirmSignUp.js

import React, { useState } from 'react';
import { Container, Box, TextField, Button, Typography } from '@mui/material';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import { cognitoConfig } from '../cognitoConfig';
import {useNavigate} from "react-router-dom";

const userPool = new CognitoUserPool(cognitoConfig);

const ConfirmSignUp = ({ username, password, setUser, callbackUrl, handleSignIn}) => {
    const navigate = useNavigate();
    const [confirmationCode, setConfirmationCode] = useState('');
    const [error, setError] = useState('');

    const handleChange = (event) => {
        setConfirmationCode(event.target.value);
    };

    const handleConfirm = () => {
        const user = new CognitoUser({
            Username: username,
            Pool: userPool,
        });

        user.confirmRegistration(confirmationCode, true, (err, result) => {
            if (err) {
                setError(err.message || JSON.stringify(err));
                return;
            }
            if (result) {
                console.log("Result : ", result)
                handleSignIn(username, password);
                navigate(callbackUrl || '/');
            }
        });
    };

    return (
        <Container maxWidth="sm">
            <Box mt={5} p={3} boxShadow={3} bgcolor="background.paper">
                <Typography variant="h4" component="h1" gutterBottom>
                    Confirm Sign Up
                </Typography>
                <TextField
                    label="Confirmation Code"
                    value={confirmationCode}
                    onChange={handleChange}
                    fullWidth
                    margin="normal"
                    required
                />
                {error && (
                    <Typography variant="body2" color="error" align="center">
                        {error}
                    </Typography>
                )}
                <Box mt={2}>
                    <Button variant="contained" color="primary" fullWidth onClick={handleConfirm}>
                        Confirm
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default ConfirmSignUp;
